import Header from './../components/header/Header'

const Home = () => {
	return (
		<>
			<Header />
			<main className="section">
				<div className="container">
					<ul className="content-list">
						<li className="content-list__item">
							<h2 className="title-2">Мои навыки:</h2>
							<p>
								Figma, Photoshop, Adobe Illustrator, Adobe XD,
								Premier Pro, Adobe After Effects, HTML, CSS, NPM,
								BootStrap, MaterialUI, Yarn, TailwindCSS
							</p>
						</li>
						<li className="content-list__item">
							<h2 className="title-2">Другое</h2>
							<p>NodeJS, MySQL, MongoDB, PHP, Laravel</p>
						</li>
					</ul>
				</div>
			</main>
		</>
	);
}

export default Home;